<template>
  <div>
    <a-modal
      width="1100px"
      v-model="isShow"
      centered
      :mask-closable="false"
      :title="`库存卡详情 数据更新时间：${data.data_updated_at}`"
    >
      <a-spin :spinning="loading">
        <a-row>
          <a-col :span="8">
            <a-descriptions
              :column="1"
              title="卡片信息"
              class="simiot-descriptions"
            >
              <a-descriptions-item label="ICCID/SIMID">
                {{ data.iccid }}
              </a-descriptions-item>
              <a-descriptions-item label="手机号码">
                {{ data.phone_number }}
              </a-descriptions-item>
              <a-descriptions-item label="IMSI">
                {{ data.imsi }}
              </a-descriptions-item>
              <a-descriptions-item label="短号">
                {{ data.short_number }}
              </a-descriptions-item>
              <a-descriptions-item label="入库时间">
                {{ data.imported_at }}
              </a-descriptions-item>
              <a-descriptions-item label="卡片材质">
                {{ data.card_type }}
              </a-descriptions-item>
              <a-descriptions-item label="卡片尺寸">
                {{ data.card_shape }}
              </a-descriptions-item>
              <a-descriptions-item label="原生贴片">
                {{ data.is_native_smt }}
              </a-descriptions-item>
            </a-descriptions>
          </a-col>

          <a-col :span="8">
            <a-descriptions
              :column="1"
              title="运营商信息"
              class="simiot-descriptions simiot-descriptions-max-7"
            >
              <a-descriptions-item label="运营商种类">
                {{ data.carrier_type }}
              </a-descriptions-item>
              <a-descriptions-item label="运营商账户">
                {{ data.carrier_account_name }}
              </a-descriptions-item>
              <a-descriptions-item label="运营商状态">
                {{ data.card_status }}
              </a-descriptions-item>
              <a-descriptions-item label="运营商沉默期">
                {{ data.carrier_silent_period_end_date }}
              </a-descriptions-item>
              <a-descriptions-item label="运营商测试期">
                {{ data.carrier_test_period_end_date }}
              </a-descriptions-item>
              <a-descriptions-item label="运营商激活时间">
                {{ data.carrier_activated_at }}
              </a-descriptions-item>
            </a-descriptions>
          </a-col>

          <a-col :span="8">
            <a-descriptions
              :column="1"
              title="运营商套餐属性"
              class="simiot-descriptions"
            >
              <a-descriptions-item label="标签">
                {{ data.tags }}
              </a-descriptions-item>
              <a-descriptions-item label="运营商套餐">
                {{ data.carrier_product_name }}
              </a-descriptions-item>
              <a-descriptions-item label="套餐周期">
                {{ data.carrier_product_service_cycle }}
              </a-descriptions-item>
              <a-descriptions-item label="服务周期">
                {{ data.service_cycle }}
              </a-descriptions-item>
              <a-descriptions-item label="APN参数">
                {{ data.apn_parameters }}
              </a-descriptions-item>
              <a-descriptions-item label="其他服务">
                {{ data.other_services }}
              </a-descriptions-item>
            </a-descriptions>
          </a-col>
        </a-row>

        <a-divider />

        <a-descriptions :column="4" title="操作历史" />
      </a-spin>

      <sim-card-event-list
        :card-id="this.id"
        table-type="sim_card_inventory"
      />

      <template slot="footer">
        <a-button
          class="ant-btn-primary"
          @click="updateCarrierData"
          :loading="isUpdateBtnLoading"
        >
          更新
        </a-button>
        <a-button class="ant-btn-primary" @click="closeModal">
          关闭
        </a-button>
      </template>
    </a-modal>
  </div>
</template>

<script>
import { findSimCardInventory, updateSimCardInventoryCarrierData } from '@/api/sim_card_inventory'
import SimCardEventList from '@/views/sim_card_events'

export default {
  name: 'ShowSimCardInventory',
  components: { SimCardEventList },
  props: {
    visible: {
      type: Boolean,
      required: true
    },
    id: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      loading: true,
      isUpdateBtnLoading: false,
      data: {}
    }
  },
  computed: {
    isShow: {
      get() {
        return this.visible
      },
      set(val) {
        this.$emit('update:visible', val)
      }
    }
  },
  created() {
    this.fetchData()
  },
  methods: {
    fetchData() {
      this.loading = true
      findSimCardInventory(this.id).then((res) => {
        if (res.code === 0) {
          this.data = res.data
        }
        this.loading = false
      })
    },

    // 更新运营商数据
    updateCarrierData() {
      this.isUpdateBtnLoading = true
      updateSimCardInventoryCarrierData(this.id).then((res) => {
        this.isUpdateBtnLoading = false
      })
    },

    closeModal() {
      this.isShow = false
    }
  }
}
</script>
